<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-1 mt-2">
        <h1>Utilities for Unity Projects</h1>
      </v-col>
    </v-row>

    <!-- The line counter button -->
    <v-row class="text-center">
      <v-col class="mb-1">
        <v-icon large @click="$router.push(consts.LINE_COUNTER_ROUTE)">{{
          consts.LINE_COUNTER_ICON
        }}</v-icon>
        <p>{{ consts.LINE_COUNTER_PAGE_TITLE }}</p>
      </v-col>
    </v-row>

    <!-- The Optimization Scanner button -->
    <v-row class="text-center">
      <v-col class="mb-1">
        <v-icon
          large
          @click="$router.push(consts.OPTIMIZATION_SCANNER_ROUTE)"
          >{{ consts.OPTIMIZATION_SCANNER_ICON }}</v-icon
        >
        <p>{{ consts.OPTIMIZATION_SCANNER_PAGE_TITLE }}</p>
      </v-col>
    </v-row>

    <!-- The Entry Point tool button -->
    <v-row class="text-center">
      <v-col class="mb-1">
        <v-icon large @click="$router.push(consts.ENTRY_POINT_ROUTE)">{{
          consts.ENTRY_POINT_ICON
        }}</v-icon>
        <p>{{ consts.ENTRY_POINT_PAGE_TITLE }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Consts from '@/js/constants';
export default {
  data: () => ({
    consts: Consts
  })

  //   methods: {
  //     menuActionClick(action) {
  //       // console.log('NavBarComp.vue -> menuActionClick. action = ' + action);
  //       if (action === Consts.LINE_COUNTER_ROUTE) {
  //         this.$router.push({
  //           path: Consts.LINE_COUNTER_ROUTE
  //         });
  //       } else if (action == Consts.OPTIMIZATION_SCANNER_ROUTE) {
  //         this.$router.push({
  //           path: Consts.OPTIMIZATION_SCANNER_ROUTE
  //         });
  //       }
  //     }
  //   }
};
</script>

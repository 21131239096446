<template>
  <!-- <v-card
    class="mx-auto overflow-hidden"
    height="400"
    width="344"
  > -->
  <div>
    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

    <v-app-bar color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <!-- https://stackoverflow.com/questions/49050145/how-to-run-vuetify-js-toolbar-title-with-router -->
      <v-toolbar-title style="cursor: pointer" @click="$router.push('/')">
        Devoolio</v-toolbar-title
      >

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute bottom temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">

          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="menuActionClick(item.action)"
            link
          >
            <!--  -->
            <!--  -->
            <!-- The icons -->
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <!-- The text -->
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            <!--  -->
            <!--  -->
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- <v-card-text>
      The navigation drawer will appear from the bottom on smaller size screens.
    </v-card-text> -->
    <!-- </v-card> -->
  </div>
</template>

<script>
import Consts from '@/js/constants';
export default {
  mounted() {
    // console.log(`NavBarComp.vue -> mounted()`);
    // console.log(`Consts.LINE_COUNTER_ROUTE = ` + Consts.LINE_COUNTER_ROUTE);
    // console.log('this.$router = ', this.$router);
    // console.log('this.$router.getRoutes = ', this.$router.getRoutes());
  },

  data: () => ({
    drawer: false,
    group: null,
    items: [
      {
        title: Consts.HOME_PAGE_TITLE,
        icon: Consts.HOME_ICON,
        action: Consts.HOME_ROUTE
      },
      {
        title: Consts.LINE_COUNTER_PAGE_TITLE,
        icon: Consts.LINE_COUNTER_ICON,
        action: Consts.LINE_COUNTER_ROUTE
      },
      {
        title: Consts.OPTIMIZATION_SCANNER_PAGE_TITLE,
        icon: Consts.OPTIMIZATION_SCANNER_ICON,
        action: Consts.OPTIMIZATION_SCANNER_ROUTE
      },
      {
        title: Consts.ENTRY_POINT_PAGE_TITLE,
        icon: Consts.ENTRY_POINT_ICON,
        action: Consts.ENTRY_POINT_ROUTE
      }
    ]
  }),

  watch: {
    group() {
      this.drawer = false;
    }
  },
  methods: {
    menuActionClick(action) {
      // console.log('NavBarComp.vue -> menuActionClick. action = ' + action);
      if (action == Consts.HOME_ROUTE) {
        this.$router.push({
          path: Consts.HOME_ROUTE
        });
      } else if (action === Consts.LINE_COUNTER_ROUTE) {
        this.$router.push({
          path: Consts.LINE_COUNTER_ROUTE
        });
      } else if (action == Consts.OPTIMIZATION_SCANNER_ROUTE) {
        this.$router.push({
          path: Consts.OPTIMIZATION_SCANNER_ROUTE
        });
      }else if (action == Consts.ENTRY_POINT_ROUTE) {
        this.$router.push({
          path: Consts.ENTRY_POINT_ROUTE
        });
      }
    }
  }
};
</script>

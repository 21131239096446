<template>
  <!-- <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  </div> -->

  <v-app>
    <NavBarComp />

    <!-- <v-app-bar app color="primary" dark> -->
    <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

    <!-- <v-toolbar-title>Devoolio</v-toolbar-title> -->

    <!-- <v-spacer></v-spacer> -->

    <!-- <div id="nav"> -->
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/extremeLog">Extreme Log</router-link> -->

    <!-- <v-btn depressed outlined to="/">Home</v-btn> -->
    <!-- <v-btn depressed outlined to="/extremeLog">E-Log</v-btn> -->
    <!-- <v-btn depressed outlined to="/lineCounter">Line Counter</v-btn> -->
    <!-- </div> -->
    <!-- </v-app-bar> -->

    <v-main>
      <!-- <HelloWorld /> -->
      <router-view />
    </v-main>
    <FooterComp />
  </v-app>
</template>

<script>
import NavBarComp from '@/components/NavBarComp.vue';
import FooterComp from '@/components/Footer.vue';
export default {
  components: { NavBarComp, FooterComp }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

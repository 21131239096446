import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/extremeLog',
    name: 'ExtremeLog',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ExtremeLog.vue')
  },
  {
    path: '/lineCounter',
    name: 'LineCounter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LineCounter.vue')
  },
  {
    path: '/optimizationScanner',
    name: 'OptimizationScanner',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/OptimizationScanner.vue')
  },
  {
    path: '/entryPoint',
    name: 'EntryPoint',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/EntryPoint.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

//Vue route guard
//https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach(async (to, from, next) => {
//   console.log('router.beforeEach. to: ', to);
//   console.log('router.beforeEach. from: ', from);
//   console.log('router.beforeEach. next: ', next);

  //March 26th 2022: for now just always redirect to lineCounter since the app only has one tool at the mo
//   if (to.path !== '/lineCounter') {
//     next({
//       path: '/lineCounter',
//       query: { redirect: to.fullPath }
//     });
//   }

  next();
});

export default router;

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

//https://docs.amplify.aws/start/getting-started/setup/q/integration/vue/#install-amplify-libraries
import Amplify, { Analytics } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

// https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/#session-tracking
Analytics.autoTrack('session', {
    // REQUIRED, turn on/off the auto tracking
    enable: true,
});

//https://docs.amplify.aws/lib/analytics/autotrack/q/platform/js/#page-view-tracking
Analytics.autoTrack('pageView', {
  // REQUIRED, turn on/off the auto tracking
  enable: true,

  // OPTIONAL, by default is 'multiPageApp'
  // you need to change it to 'SPA' if your app is a single-page app like React
  type: 'SPA'
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');

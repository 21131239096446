//Needed to use a vanilla js object as consts since Vue.prototype isn't available at
//start-up in certain scripts such as router/index.js
//https://stackoverflow.com/questions/47597384/canonical-way-to-define-common-constants-in-vue-js
export default Object.freeze({
  END_OF_LINE_COMMENT: '//Added by Devoolio.com',
  NEW_LINE: '\n',
  NEW_LINE_WITH_ESCAPE: '\\n', //used so we don't produce a new line in the C# file
  DEVOOLIO_LOG_FILE_NAME: 'DevoolioLog.txt',
  GET_CURRENT_METHOD_NAME:
    'System.Reflection.MethodBase.GetCurrentMethod().Name', //https://stackoverflow.com/questions/2968352/using-system-reflection-to-get-a-methods-full-name
  GET_FRAME_COUNT_METHOD: 'Time.frameCount',

  //page titles
  HOME_PAGE_TITLE: 'Home',
  LINE_COUNTER_PAGE_TITLE: 'Line Counter',
  OPTIMIZATION_SCANNER_PAGE_TITLE: 'Optimization Scanner',
  ENTRY_POINT_PAGE_TITLE: 'Entry Point (Beta)',

  //page routes
  HOME_ROUTE: '/',
  LINE_COUNTER_ROUTE: '/lineCounter',
  OPTIMIZATION_SCANNER_ROUTE: '/optimizationScanner',
  ENTRY_POINT_ROUTE: '/entryPoint',

  //page icons
  HOME_ICON: 'mdi-home',
  LINE_COUNTER_ICON: 'mdi-text-box-search',
  OPTIMIZATION_SCANNER_ICON: 'mdi-speedometer',
  ENTRY_POINT_ICON: 'mdi-ray-start-arrow',

  //https://answers.unity.com/questions/452946/which-unity-functions-are-generally-slowbad-for-pe.html
  heavyFunctionsToSearch: [
    'GameObject.Find',
    'Instantiate',
    'Resources.FindObjectsOfTypeAll',
    'Destroy',
    'FindObjectOfType',
    'GetComponentsInChildren',
    'BroadcastMessage',
    'SendMessageUpwards',
    'Debug.Log',
    'GetComponents', //check upto here
    'AddComponent',
    'FindWithTag',
    'SendMessage',
    'OnGUI',
    'GetComponent',
    'OverlapSphere',
    'RaycastAll',
    '.magnitude',
    '.normalized'
  ],

  //https://docs.unity3d.com/Manual/ExecutionOrder.html
  builtInUnityFunctionsArray: [
    'Awake',
    'Start',
    // 'OnEnable',
    // 'OnDisable',
    'Update',
    // 'FixedUpdate',
    // 'LateUpdate',
    // 'OnTriggerEnter',
    // 'OnCollisionEnter'
  ]
});

//https://answers.unity.com/questions/452946/which-unity-functions-are-generally-slowbad-for-pe.html
// export const heavyFunctionsToSearch = [
//   'GameObject.Find',
//   'Instantiate',
//   'Resources.FindObjectsOfTypeAll',
//   'Destroy',
//   'FindObjectOfType',
//   'GetComponentsInChildren',
//   'BroadcastMessage',
//   'SendMessageUpwards',
//   'Debug.Log',
//   'GetComponents', //check upto here
//   'AddComponent',
//   'FindWithTag',
//   'SendMessage',
//   'OnGUI',
//   'GetComponent',
//   'OverlapSphere',
//   'RaycastAll',
//   '.magnitude',
//   '.normalized'
// ];

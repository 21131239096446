/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7ee69881-3cf8-4a03-96a1-a93523983015",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_L0fUwWsdn",
    "aws_user_pools_web_client_id": "213ji187t02d0h8du87dmep21s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_mobile_analytics_app_id": "34d36dbfc8c243608c7c41af907a219e",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
